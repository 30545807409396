'use client';

import { ChangelogSection, ComponentsSection, DiscordSection, GitHubSection, TwitterSection } from '@/app/lib/config/routing';
import { useAppStore } from '@/app/lib/stores/app.store';
import ChevronDownIcon from '@/app/ui/assets/icons/chevron-down-icon';
import type { NavItem as NavItemType } from '@/app/ui/navigation/config';
import { navSections } from '@/app/ui/navigation/config';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

const NavSections = [ComponentsSection, ChangelogSection, GitHubSection, TwitterSection, DiscordSection]; //, FigmaSection]; temporarily hidden

export default function NavLinks() {
  const { setIsSideNavOpened, sideNavCollapsedSections, setSideNavCollapsedSections } = useAppStore();
  const navSectionClassName = 'font-semibold text-xs text-black leading-none';

  const handleCollapseSection = (sectionName: string) => {
    const isCollapsed = sideNavCollapsedSections.includes(sectionName);
    if (isCollapsed) {
      setSideNavCollapsedSections(sideNavCollapsedSections.filter(section => section !== sectionName));
    } else {
      setSideNavCollapsedSections([...sideNavCollapsedSections, sectionName]);
    }
  };

  return (
    <div className='flex w-full flex-col gap-4 pl-1 md:gap-3'>
      {NavSections.map(({ name, href }) => (
        <Link
          key={name.toLowerCase()}
          href={href}
          className={`md:hidden ${navSectionClassName}`}
          onClick={() => {
            setIsSideNavOpened(false);
          }}
        >
          {name}
        </Link>
      ))}
      {navSections.map(({ label, icon: SectionIcon, items, href }) => {
        const isCollapsed = sideNavCollapsedSections.includes(label);

        return (
          <div key={label} className='flex flex-col gap-4'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center justify-start'>
                {SectionIcon && (
                  <div className={clsx('mr-2.5 rounded-lg border border-gray-200 bg-gray-50 p-0.5')}>
                    <SectionIcon />
                  </div>
                )}
                <label className={navSectionClassName}>{label}</label>
              </div>
              <ChevronDownIcon
                className={`transition-all duration-300 ease-in-out ${isCollapsed ? '' : 'rotate-180 '}cursor-pointer`}
                onClick={() => {
                  handleCollapseSection(label);
                }}
              />
            </div>
            <div
              className={`flex flex-col overflow-hidden transition-[max-height,opacity] duration-300 ease-in-out ${
                isCollapsed ? 'max-h-0 opacity-0' : 'max-h-[600px]'
              }`}
            >
              {items.map(link => (
                <NavItem key={link.name} baseHref={href} {...link} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

const NavItem = (props: NavItemType & { baseHref: string }) => {
  const pathname = usePathname();
  const isActiveLink = pathname.endsWith(props.href);
  const setIsSideNavOpened = useAppStore(state => state.setIsSideNavOpened);

  return (
    <Link
      href={props.direct ? props.href : `${props.baseHref}${props.href}`}
      className={clsx('group relative flex h-8 items-center justify-start rounded pl-6 hover:bg-slate-50 focus:bg-slate-50 focus:outline-0', {
        'bg-slate-100': isActiveLink,
      })}
      onClick={() => {
        setIsSideNavOpened(false);
      }}
    >
      <div className={clsx('absolute inset-y-0 left-3', { 'w-0.5 bg-blue-700': isActiveLink, 'w-[1px] bg-slate-200': !isActiveLink })} />
      <p className={clsx('text-xs font-medium group-hover:text-slate-800', { 'font-semibold text-blue-700': isActiveLink, 'text-slate-600': !isActiveLink })}>
        {props.name}
      </p>
      <ChevronDownIcon
        className={`absolute right-2 top-1/2 size-4 -translate-y-1/2 -rotate-90 stroke-transparent transition-all duration-100 ease-in-out group-hover:stroke-slate-800`}
      />
    </Link>
  );
};
